var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"wrapper illustration illustration_shield"},[_c('Backlink',{attrs:{"title":"detail","service":"mortgage","backlink":"/mortgage/old-mortgage-not-sold-realty"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('p',{staticClass:"promo promo_blocks"},[_vm._v(_vm._s(_vm._f("localize")("s2p7_text_5")))]),_c('div',{staticClass:"form__group"},[_c('v-text-field',{ref:"price",staticClass:"input_number",class:{ invalid: _vm.$v.price.$dirty && !_vm.$v.price.required },attrs:{"inputmode":"numeric","pattern":"[0-9]*","hint":_vm._f("localize")((_vm.$v.price.$dirty && !_vm.$v.price.required
                  ? 'form_reqiered'
                  : 'empty')),"placeholder":_vm._f("localize")('s4p9_placeholder_1'),"append-icon":"icon-₪","outlined":""},on:{"input":function($event){return _vm.checkForm(_vm.$v.price)},"keypress":_vm.onKeyPressHandler,"keyup":function($event){_vm.onKeyUpPriceHandler('price', _vm.price);
                _vm.countCapital(_vm.price);}},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('p',{staticClass:"subtext"},[_vm._v(" "+_vm._s(_vm.capital_1.toLocaleString())+" "),_c('span',{staticClass:"icon-₪"}),_vm._v(" "+_vm._s(_vm._f("localize")("s2p7_subtext_1"))+" ")]),_c('p',{staticClass:"question"},[_vm._v(_vm._s(_vm._f("localize")("s2p7_text_3")))]),_c('div',{staticClass:"form__group"},[_c('v-text-field',{ref:"add_capital",staticClass:"input_number",class:{
                invalid: _vm.$v.add_capital.$dirty && !_vm.$v.add_capital.required,
              },attrs:{"inputmode":"numeric","pattern":"[0-9]*","hint":_vm._f("localize")((_vm.$v.add_capital.$dirty && !_vm.$v.add_capital.required
                  ? 'form_reqiered'
                  : 'empty')),"placeholder":_vm._f("localize")('s4p9_placeholder_1'),"append-icon":"icon-₪","outlined":""},on:{"input":function($event){return _vm.checkForm(_vm.$v.add_capital)},"keypress":_vm.onKeyPressHandler,"keyup":function($event){_vm.onKeyUpPriceHandler('add_capital', _vm.add_capital);
                _vm.countSumCapital(_vm.add_capital);}},model:{value:(_vm.add_capital),callback:function ($$v) {_vm.add_capital=$$v},expression:"add_capital"}})],1),_c('p',{staticClass:"subtext"},[_vm._v(" "+_vm._s(_vm.capital_2.toLocaleString())+" "),_c('span',{staticClass:"icon-₪"}),_vm._v(" "+_vm._s(_vm._f("localize")("s2p7_subtext_2"))+" ")]),_c('button',{staticClass:"button button__form",class:{ button__form_disabled: !_vm.success },attrs:{"type":"button"},on:{"click":_vm.submitHandler}},[_vm._v(" "+_vm._s(_vm._f("localize")("form_continue"))+" ")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }