export const numberMixin = {
  methods: {
    onKeyPressHandler ($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      }
    },
    onKeyUpArrayHandler (number, idx, element) {
      if (number) {
        number = this.parseNumber(number)
        this.accounts[idx][element] = this.formatNumber(number)
      }
    },
    onKeyUpPriceHandler (field, value) {
      if (value) {
        const number = this.parseNumber(value)
        this[field] = this.formatNumber(number)
      }
    },
    parseInput (field, value) {
      this.client[field] = value.replace(/[^а-яА-ЯёЁ ]/g, '')
    },
    formatNumber (number) {
      if (number) {
        return Number(number)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      }
    },
    parseNumber (number) {
      if (number) {
        return String(number).replace(/ /g, '')
      }
    },
    syncSlider (num, slider, reverse = false) {
      if (reverse) {
        this[slider] = this.parseNumber(this[num])
      } else {
        this[num] = this.formatNumber(this[slider])
      }
    }
  }
}
