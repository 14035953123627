<template>
  <div class="form">
    <div class="wrapper illustration illustration_shield">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/old-mortgage-not-sold-realty" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="promo promo_blocks">{{ "s2p7_text_5" | localize }}</p>

            <div class="form__group">
              <v-text-field
                v-model="price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.price)"
                @keypress="onKeyPressHandler"
                @keyup="
                  onKeyUpPriceHandler('price', price);
                  countCapital(price);
                "
                :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                :hint="
                  ($v.price.$dirty && !$v.price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'s4p9_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="price"
                outlined
              ></v-text-field>
            </div>
            <p class="subtext">
              {{ capital_1.toLocaleString() }} <span class="icon-₪"></span>
              {{ "s2p7_subtext_1" | localize }}
            </p>

            <p class="question">{{ "s2p7_text_3" | localize }}</p>
            <div class="form__group">
              <v-text-field
                v-model="add_capital"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.add_capital)"
                @keypress="onKeyPressHandler"
                @keyup="
                  onKeyUpPriceHandler('add_capital', add_capital);
                  countSumCapital(add_capital);
                "
                :class="{
                  invalid: $v.add_capital.$dirty && !$v.add_capital.required,
                }"
                :hint="
                  ($v.add_capital.$dirty && !$v.add_capital.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'s4p9_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="add_capital"
                outlined
              ></v-text-field>
            </div>
            <p class="subtext">
              {{ capital_2.toLocaleString() }} <span class="icon-₪"></span>
              {{ "s2p7_subtext_2" | localize }}
            </p>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage-self-money',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    capital_1: 0,
    capital_2: 0,
    price: mortgage.old_price_not_sold || null,
    add_capital: null
  }),
  validations: {
    price: { required },
    add_capital: { required }
  },
  created () {
    this.price = mortgage.old_price_not_sold ? this.formatNumber(mortgage.old_price_not_sold) : null
  },
  methods: {
    countCapital (price) {
      price = parseInt(this.parseNumber(price))
      if (mortgage.old_price && mortgage.old_price > 0) {
        const oldMortgage = mortgage.old_mortgage_not_sold ? parseInt(mortgage.old_mortgage_not_sold) : 0
        this.capital_1 = price > 0 ? (mortgage.old_price > oldMortgage + price ? mortgage.old_price - oldMortgage - price : 0) : mortgage.old_price
      }
    },
    countSumCapital (price) {
      price = parseInt(this.parseNumber(price))
      const capital = this.capital_1 > 0 ? parseInt(this.parseNumber(this.capital_1)) : 0
      this.capital_2 = price > 0 ? capital + price : capital
    },
    submitHandler () {
      this.price = this.parseNumber(this.price)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      mortgage.old_price_not_sold = this.price
      mortgage.final_capital = this.capital_2

      this.$cookies.set('mortgage', mortgage)
      this.saveServiceInfo('mortgage', '/mortgage/banks', { additional_capital: this.capital_2 })
    }
  }
}
</script>
